import Vue from '@/vueTyped';
import debounce from 'lodash/debounce';
export default Vue.extend({
    name: 'Integrations',
    components: {},
    data() {
        return {
            debouncedSaveUserData: () => { },
            userData: {
                communicationPreferences: {},
                avatar: {},
                addressComponents: {},
            },
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.account.currentUser;
        },
    },
    watch: {
        currentUser: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.userData = val;
                }
            },
        },
    },
    created() {
        this.debouncedSaveUserData = debounce(this.saveUserData, 500);
    },
    methods: {
        toggleFacebookIntegration() {
            if (this.currentUser && this.currentUser.fbUserId) {
                console.log('TODO: turn off FB');
            }
            else {
                console.log('TODO: init FB integration code');
            }
        },
        saveUserData() {
            this.$store.dispatch('updateUserDetails', this.userData);
            console.log('save', this.userData);
        },
    },
});
